<template>
  <div>
    <el-form label-width="90px" inline size="mini">
      <el-form-item label="搜索">
        <el-input style="width:220px;" placeholder="请输入姓名/手机号/充值号码" v-model="search.search" @keyup.native.enter="handleCurrentChange(1)"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select style="width:150px;" v-model="search.type" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="抵扣券" value="0"></el-option>
          <el-option label="洗车券" value="1"></el-option>
          <el-option v-for="(item,i) in menuList" :key="i" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="使用状态">
        <el-select style="width:150px;" v-model="search.isUsed" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="(item,i) in stateList" :key="i" :label="item" :value="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付状态">
        <el-select style="width:150px;" v-model="search.payStatus" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="(item,i) in payList" :key="i" :label="item" :value="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="购买时间">
        <el-date-picker v-model="search.purchaseStartTime" type="datetime" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker> 至
        <el-date-picker v-model="search.purchaseEndTime" type="datetime" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker>
      </el-form-item>
      <el-form-item label="使用时间">
        <el-date-picker v-model="search.usedStartTime" type="datetime" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker> 至
        <el-date-picker v-model="search.usedEndTime" type="datetime" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        <!-- <el-button type="primary" icon="el-icon-folder-opened" @click="exportList">导出</el-button> -->
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 230px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="userName" align="center" label="姓名"></el-table-column>
      <el-table-column prop="phone" align="center" label="手机号"></el-table-column>
      <el-table-column prop="name" align="center" label="消费名称"></el-table-column>
      <el-table-column prop="totalIntegral" align="center" label="类型">
        <template slot-scope="scope">
          {{typeFilter(scope.row.type)}}
        </template>
      </el-table-column>
      <el-table-column prop="price" align="center" label="金额"></el-table-column>
      <el-table-column prop="purchaseTime" align="center" label="购买时间"></el-table-column>
      <el-table-column prop="usedTime" align="center" label="使用时间"></el-table-column>
      <el-table-column align="center" label="支付状态">
        <template slot-scope="scope">
          {{payList[scope.row.payStatus]}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="使用状态">
        <template slot-scope="scope">
          <span v-if="scope.row.supplierType !=3">{{stateList[scope.row.isUsed]}}</span>
          <span v-else>{{stateList2[scope.row.isUsed]}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-view" style="color:#333" type="text" @click="showDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
    <DetailConsumption :show.sync="show" :item="rowItem" :menuList="menuList"></DetailConsumption>
  </div>
</template>

<script>
import DetailConsumption from './components/DetailConsumption.vue'
import { getConsumptionRecordsPage } from '@/api/customer'
import { getMobileMenuList } from '@/api/appSetting'
import { exportCommon } from "@/utils/utils";
export default {
  name: 'consumptionQuery',
  components: { DetailConsumption },
  data() {
    return {
      search: {
        pageNum: 1,
        pageSize: 10,
        search: '',
        purchaseStartTime: '',
        purchaseEndTime: '',
        usedStartTime: '',
        usedEndTime: '',
        isUsed: '',
        payStatus: ''
      },
      total: 0,
      list: [],
      show: false,
      rowItem: {},
      typeList: ['抵扣券', '洗车券', '充值'],
      menuList: [],
      stateList: ['未使用', '已使用', '已撤回', '已赠送'],
      stateList2: ['未到账', '已到账', '部分到账'],
      payList: ['预支付', '支付成功', '支付失败', '已退款', '退款中', '部分退款', '取消中'],
    }
  },
  created() {
    this.getList()
    this.getTypeList()
  },
  computed: {
    typeFilter() {
      return function (val) {
        if (val == 0) {
          return '抵扣券'
        } else if (val == 1) {
          return '洗车券'
        } else {
          let value = this.menuList.find(item => item.id == val) || {}
          return value.label || ''
        }
      }
    }
  },
  methods: {
    getTypeList() {
      getMobileMenuList({ level: "1" }).then(res => {
        this.menuList = res.data || []
      })
    },
    getList() {
      let param = Object.assign({}, this.search)
      getConsumptionRecordsPage(param).then(res => {
        let result = res.data || {}
        this.list = result.records || []
        this.total = result.total || 0
      })
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getList()
    },
    showDetail(item) {
      this.show = true
      this.rowItem = item
    },
    exportList() {
      let param = {};
      Object.assign(param, this.search)
      exportCommon("/WlynProductRecharge/exportConsumptionRecordsExcel", param);
    }

  }
}
</script>