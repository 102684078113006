<template>
  <el-dialog title="消费详情" :visible.sync="show" width="500px" :before-close="handleClose">
    <el-form size="mini" label-width="100px">
      <el-form-item label="名称">
        {{item.name}}
      </el-form-item>
      <el-form-item label="类型">
        {{typeFilter(item.type)}}
      </el-form-item>
      <el-form-item label="姓名">
        {{item.userName}}
      </el-form-item>
      <el-form-item label="手机号">
        {{item.phone}}
      </el-form-item>
      <el-form-item label="充值账号">
        {{item.rechargeAccount}}
      </el-form-item>
      <el-form-item label="总价">
        {{item.price}}
      </el-form-item>
      <el-form-item label="支付金额">
        {{item.payAmount}}
      </el-form-item>
      <el-form-item label="支付积分">
        {{item.payIntegral}}
      </el-form-item>
      <el-form-item label="订单单号" v-if="item.payNumber">
        <div v-for="(it,index) in item.payNumber.split(',')" :key="index">{{it}}</div>
        <!-- {{item.payNumber}} -->
      </el-form-item>
      <el-form-item label="购买时间">
        {{item.purchaseTime}}
      </el-form-item>
      <el-form-item label="支付状态">
        {{payList[item.payStatus]}}
        <!-- <span v-if="item.supplierType !=3">{{stateList[item.isUsed]}}</span>
        <span v-else>{{stateList2[item.isUsed]}}</span> -->
        <!-- {{stateList[item.isUsed]}} -->
      </el-form-item>
      <el-form-item label="使用状态">
        <span v-if="item.supplierType !=3">{{stateList[item.isUsed]}}</span>
        <span v-else>{{stateList2[item.isUsed]}}</span>
        <!-- {{stateList[item.isUsed]}} -->
      </el-form-item>
      <el-form-item label="使用时间">
        {{item.usedTime}}
      </el-form-item>
    </el-form>
    <div>
      <el-button @click="handleClose">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DetailConsumption',
  props: ['show', 'item', 'menuList'],
  data() {
    return {
      typeList: ['抵扣券', '洗车券', '充值'],
      stateList: ['未使用', '已使用', '已撤回', '已赠送'],
      stateList2: ['未到账', '已到账', '部分到账'],
      payList: ['预支付', '支付成功', '支付失败', '已退款', '退款中', '部分退款', '取消中'],

    }
  },
  computed: {
    typeFilter() {
      return function (val) {
        if (val == 0) {
          return '抵扣券'
        } else if (val == 1) {
          return '洗车券'
        } else {
          let value = this.menuList.find(item => item.id == val) || {}
          return value.label || ''
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:show', false)
    },
  }
}
</script>

<style>
</style>